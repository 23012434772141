import React from 'react';
import Header from '../Header/header';
import SecondHeader from '../Header/secondheader';

export const Page404 = () => (
  <>
    {localStorage.getItem('jwtToken') == null ? <Header /> : <SecondHeader />}
    <div className="text-center pt-150 px-5">
      <h1 className="pt-5">Sorry, this page could not be found.</h1>
      <h4>
        The page you are looking for either does not exist or has been moved.
      </h4>
    </div>
  </>
);
