import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../index.css';
import ReactPaginate from 'react-paginate';
import { AiOutlinePlus } from 'react-icons/ai';
import Header from '../Header/header';
import SecondHeader from '../Header/secondheader';
import Footer from '../Footer/index';

class ExemplarBlogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      myblog: 'all',
      color: '',
      pagemyblog: [],
      perPage: 9,
      currentPage: 1,
      currentblogPage: 1,
      pageCount: 0,
      offset: 0,
      blogpageCount: 0,
      activepage: 1,
      offsetblog: 0,
      blogData: [],
    };
  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      perPage: Math.ceil(pageNumber / 3),
    });
  }

  componentDidMount() {
    this.viewAllData();
  }

  getCard(data, index) {
    return (
      <React.Fragment key={index}>
        <div className="col-md-4 d-flex pb-3">
          <div
            className={'mx-auto single-blog-card card border shadow-sm'}
            style={{ width: '300px' }}
          >
            <div className="blogs-tag">
              {data.tags !== null
                ? data.tags.slice(0, 3).map((tags, key) => (
                    <span
                      key={key}
                      className="mar-r-8 badge badge-pill badge-primary"
                    >
                      {tags.value}
                    </span>
                  ))
                : null}
            </div>
            <img
              src={data.cover}
              className="card-img-top position-relative"
              alt="blog"
              style={{ height: '160px' }}
              onClick={() =>
                this.props.history.push(`exemplar-blog/${data.id}`)
              }
            />

            <div
              className="card-body"
              style={{
                color: '#435370',
                padding: '14px',
              }}
            >
              <h3
                style={{
                  cursor: 'pointer',
                  minHeight: 'unset',
                  fontSize: '18px',
                  fontWeight: '800',
                }}
                className="h5 card-title"
                onClick={() =>
                  this.props.history.push(`exemplar-blog/${data.id}`)
                }
              >
                {data.title.length > 80
                  ? data.title.substring(0, 80) + '...'
                  : data.title}
              </h3>
              <p
                className="card-text"
                style={{ lineHeight: '1.35', color: 'rgb(60, 76, 107)' }}
              >
                {data.excerpt.length > 120
                  ? data.excerpt.substring(0, 120) + '...'
                  : data.excerpt}
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  viewAllData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/public-blogs`, {
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const blogList = data.blogs;
        const blog = blogList.slice(
          this.state.offsetblog,
          this.state.offsetblog + this.state.perPage
        );

        const blogData = blog.map((data, index) => this.getCard(data, index));
        this.setState({
          blogpageCount: Math.ceil(blogList.length / this.state.perPage),
          blogData,
        });
      });
  };

  handleBlogPageClick = (e) => {
    const selectedblogPage = e.selected;
    const offsetblog = selectedblogPage * this.state.perPage;

    this.setState(
      {
        currentblogPage: selectedblogPage,
        offsetblog: offsetblog,
      },
      () => {
        this.viewAllData();
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        {localStorage.getItem('jwtToken') == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}
        <section className="our-blog-section gray-light-bg single-blog-container">
          <div className="blog-container container mx-auto shadow rounded">
            <div className="row">
              <div className="col-md-6">
                <div className="section-heading mb-5">
                  <h2>Exemplar Blogs</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.blogData}
              {this.state.blogData.length > 0 ? (
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.blogpageCount}
                  forceSelected={this.state.currentblogPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={this.handleBlogPageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              ) : (
                <div>
                  <p className="px-4">No blog found.</p>
                </div>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(ExemplarBlogs);
