import React from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';

class TagsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedOption: null,
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/forums/tags`, {
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          data: data.tags,
        })
      );
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <div className="dropdown-categs">
        <Select
          isMulti="true"
          name="tags"
          value={selectedOption}
          onChange={this.handleChange}
          required
          options={this.state.data}
        />
      </div>
    );
  }
}

export default withRouter(TagsDropdown);
