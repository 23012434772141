/* eslint-disable default-case */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import SecondHeader from '../Header/secondheader';
import moment from 'moment';
import Footer from '../../components/Footer/index';
import ReactPaginate from 'react-paginate';
import { FaCalendarAlt, FaRegClock } from 'react-icons/fa';
import DOMPurify from 'dompurify';
import Select from 'react-select';
import 'react-notifications/lib/notifications.css';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';

class Challenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      event: 'upcoming',
      currentPage: 1,
      pageCount: 0,
      offset: 0,
      activepage: 1,
      perPage: 9,
      pastoffset: 0,
      pastpageCount: 0,
      searchPageCount: 0,
      currentpastPage: 0,
      currentEventPage: 0,
      searchOffSet: 0,
      selectedTag: null,
      upcomingData: [],
      pastData: [],
      searchData: [],
    };
  }

  createNotification = (type) => {
    switch (type) {
      case 'info':
        NotificationManager.info('Info message');
        break;
      case 'success':
        NotificationManager.success(
          'We will see you there!',
          'Your RSVP is confirmed!',
          3000
        );
        break;
      case 'warning':
        NotificationManager.warning(
          'Warning message',
          'Close after 3000ms',
          3000
        );
        break;
      case 'error':
        NotificationManager.error(
          'Uh-oh! It would be great if you come.',
          'Your RSVP has been canceled.',
          3000
        );
        break;
    }
  };

  enrollEvent(id) {
    const sid = localStorage.getItem('studentId');

    fetch(`${process.env.REACT_APP_API_URL}/participate/${sid}/event/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
        this.createNotification('success');
        this.upcomingEvent();
      });
  }

  unEnrollEvent(id) {
    const sid = localStorage.getItem('studentId');
    fetch(`${process.env.REACT_APP_API_URL}/unparticipate/${sid}/event/${id}`, {
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
        this.createNotification('error');
        this.upcomingEvent();
      });
  }

  getCard(events) {
    const eventDescription =
      events.desc?.length > 140
        ? events.desc.substring(0, 140) + '...'
        : events.desc;
    const currentDate = new Date();
    const enrollText = events.isRegistered === true ? 'Enrolled' : 'Enroll';
    return (
      <React.Fragment>
        <div className="col-md-6 col-xl-4">
          <div
            className="mx-auto single-blog-card card border shadow-sm"
            style={{ width: '300px' }}
          >
            <div className="blogs-tag">
              {events.tags.map((tag, indext) => (
                <span
                  key={indext}
                  className="mar-r-8 badge badge-pill badge-primary"
                >
                  {tag}
                </span>
              ))}
            </div>
            <img
              src={events.cover}
              className="card-img-top position-relative cursor-pointer"
              alt="event"
              style={{ height: '160px' }}
              onClick={() =>
                this.props.history.push(`single_challenge/${events.id}`)
              }
            />
            <div
              className="card-content"
              style={{
                maxHeight: '210px',
                color: '#435370',
                padding: '14px',
              }}
            >
              <p
                className="event-title cursor-pointer mb-1"
                onClick={() =>
                  this.props.history.push(`single_challenge/${events.id}`)
                }
              >
                {events.title}
              </p>
              <div className="d-flex" style={{ fontSize: '13px' }}>
                <div className="mr-3 pl-0 pb-2">
                  <FaCalendarAlt className="mr-2" />
                  {moment(events.start_time).format('LL')}
                </div>
                <div className="pb-2">
                  <FaRegClock className="mr-2" />
                  {moment(events.start_time).format('hh:mm A')}
                </div>
              </div>
              <div className="event-desc">
                <p
                  style={{ color: '#435370', lineHeight: '1.35' }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(eventDescription),
                  }}
                ></p>
              </div>
              <div className="post-tags event-tags"></div>
            </div>
            <div className="d-flex" style={{ width: '100%' }}>
              <div
                style={{ fontSize: '12px', width: '100%' }}
                className="d-flex p-2"
              >
                <div className="circle mx-2"></div>
                <span>{events.event_creator.slice(0, 10)}</span>
              </div>
              <div
                className="d-flex justify-content-end"
                style={{ width: '100%' }}
              >
                <Link
                  to={`single_challenge/${events.id}`}
                  className="btn btn-outline-primary know-more"
                >
                  Know More
                </Link>
                {moment(events.end_time).unix() >
                  moment(currentDate).unix() && (
                  <button
                    className="btn btn-primary enroll-button"
                    onClick={() => {
                      events.isRegistered === true
                        ? this.unEnrollEvent(events.id)
                        : this.enrollEvent(events.id);
                    }}
                  >
                    {enrollText}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  upcomingEvent() {
    const sId = localStorage.getItem('studentId');
    fetch(
      `${process.env.REACT_APP_API_URL}/scheduled-challenges?student=${sId}`,
      {
        headers: {
          'x-access-token': localStorage.getItem('jwtToken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        const upcomingList = result.events;
        const curentTime = new Date();
        const avaliable = [];

        for (var i = 0; i < upcomingList.length; i++) {
          if (
            moment(upcomingList[i].end_time).unix() > moment(curentTime).unix()
          ) {
            avaliable.push(upcomingList[i]);
          }
        }

        avaliable.sort(function (x, y) {
          return moment(x.start_time).unix() - moment(y.start_time).unix();
        });

        const upcoming = avaliable.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        const upcomingData = upcoming.map((events) => this.getCard(events));
        this.setState({
          pageCount: Math.ceil(avaliable.length / this.state.perPage),
          upcomingData,
        });
      });
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.upcomingEvent();
      }
    );
  };

  pastEvent() {
    const sId = localStorage.getItem('studentId');
    fetch(
      `${process.env.REACT_APP_API_URL}/scheduled-challenges?student=${sId}`,
      {
        headers: {
          'x-access-token': localStorage.getItem('jwtToken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        const pastList = result.events;

        const curentTime = new Date();
        const past = [];

        for (var i = 0; i < pastList.length; i++) {
          if (
            moment(pastList[i].end_time).unix() <= moment(curentTime).unix()
          ) {
            past.push(pastList[i]);
          } else {
          }
        }

        past.sort(function (x, y) {
          return moment(y.start_time).unix() - moment(x.start_time).unix();
        });

        const pastEvent = past.slice(
          this.state.pastoffset,
          this.state.pastoffset + this.state.perPage
        );

        const pastData = pastEvent.map((events) => this.getCard(events));
        this.setState({
          pastpageCount: Math.ceil(past.length / this.state.perPage),
          pastData,
        });
      });
  }

  handlepastPageClick = (e) => {
    const selectedpastPage = e.selected;
    const pastoffset = selectedpastPage * this.state.perPage;

    this.setState(
      {
        currentpastPage: selectedpastPage,
        pastoffset: pastoffset,
      },
      () => {
        this.pastEvent();
      }
    );
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.searchEvents(e.target.search.value);
  };

  searchEvents = (term, isTagFilter) => {
    isTagFilter = isTagFilter ? isTagFilter : '';
    this.setState({ event: 'search' });
    fetch(
      `${process.env.REACT_APP_API_URL}/search/events?searchParam=${term}&isTagFilter=${isTagFilter}`,
      {
        headers: {
          'x-access-token': localStorage.getItem('jwtToken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const searchList = data.events ? data.events : [];
        const search = searchList.slice(
          this.state.searchOffSet,
          this.state.searchOffSet + this.state.perPage
        );
        const searchData = search.map((data) => this.getCard(data));
        this.setState({
          searchArgs: { term, isTagFilter },
          searchPageCount: Math.ceil(searchList.length / this.state.perPage),
          searchData,
        });
      });
  };

  handleSearchPageClick = (e) => {
    const selectedSearchPage = e.selected;
    const searchOffSet = selectedSearchPage * this.state.perPage;

    this.setState(
      {
        currentEventPage: selectedSearchPage,
        searchOffSet: searchOffSet,
      },
      () => {
        this.searchEvents(
          this.state.searchArgs.term,
          this.state.searchArgs.isTagFilter
        );
      }
    );
  };

  componentDidMount() {
    this.upcomingEvent();
    this.pastEvent();
    this.getEventTags();
  }

  getEventTags = () => {
    fetch(`${process.env.REACT_APP_API_URL}/event/tags`, {
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          tags: data.tags,
        });
      });
  };

  handleTagFilter = (selectedTag) => {
    this.setState({ selectedTag });
    this.searchEvents(selectedTag.label, true);
  };

  switchRender(type) {
    switch (type) {
      case 'upcoming':
        return (
          <div>
            <div className="row">{this.state.upcomingData}</div>
            {this.state.upcomingData.length > 0 ? (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                forceSelected={this.state.currentPage}
                pageRangeDisplayed={4}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            ) : (
              <div>
                <p>
                  Oops! AI Facilitator community tutors have not added any
                  challenge yet. Please hold back and explore other options.
                </p>
              </div>
            )}
          </div>
        );
      case 'past':
        return (
          <div>
            <div className="row">{this.state.pastData}</div>
            {this.state.pastData.length > 0 ? (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.pastpageCount}
                forceSelected={this.state.currentpastPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handlepastPageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            ) : (
              <div>
                <p>
                  Oops! AI Facilitator community tutors have not added any
                  challenge that have past yet. Please hold back and explore
                  other options.
                </p>
              </div>
            )}
          </div>
        );
      case 'search':
        return (
          <div className="row">
            {this.state.searchData}
            {this.state.searchData.length > 0 ? (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.searchPageCount}
                forceSelected={this.state.currentEventPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handleSearchPageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            ) : (
              <div>
                <p>
                  Oops! We were not able to find any challenge related to your
                  search. Please look for other search parameters.
                </p>
              </div>
            )}
          </div>
        );
    }
  }

  render() {
    return (
      <React.Fragment>
        <SecondHeader />
        <section className="our-blog-section gray-light-bg single-blog-container">
          <div className="blog-container container mx-auto shadow rounded">
            <div className="row">
              <div className="col-md-12">
                <div className="section-heading">
                  <h2>Challenges</h2>
                  <p className="sub-heading">
                    Enhance your learning experience by participating in the
                    latest webinars and challenges. Click on any challenge to
                    know more.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-xl-5 d-flex flex-column flex-md-row mb-3 mb-xl-0 align-items-center justify-content-md-center justify-content-xl-end">
                <button
                  className={
                    this.state.event === 'upcoming'
                      ? 'btn-blue mw-100 mb-2 mb-md-0'
                      : 'btn-blue-border mw-100 mb-2 mb-md-0'
                  }
                  style={{ width: '370px' }}
                  onClick={() =>
                    this.setState({
                      event: 'upcoming',
                    })
                  }
                >
                  Ongoing Challenges
                </button>
                <button
                  className={
                    this.state.event === 'past' ? 'btn-blue' : 'btn-blue-border'
                  }
                  style={{ width: '300px' }}
                  onClick={() =>
                    this.setState({
                      event: 'past',
                    })
                  }
                >
                  Past Challenges
                </button>
              </div>
              <div className="col-xl-7 mb-3 mb-md-0 d-flex flex-column flex-md-row align-items-center justify-content-md-center justify-content-xl-end">
                <div
                  className="w-250 flex-shrink-0 mr-3 mb-2 mb-md-0"
                  style={{ zIndex: 999 }}
                >
                  <Select
                    placeholder="Filter through tags"
                    className="form-select"
                    value={this.state.selectedTag}
                    onChange={this.handleTagFilter}
                    options={this.state.tags}
                  />
                </div>
                <form
                  className="elem-float-right d-flex align-items-start"
                  onSubmit={this.onSubmit}
                >
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Challenge"
                    className="search-input"
                  />
                  <input
                    type="submit"
                    value="Search"
                    className="btn-blue mr-0"
                  />
                </form>
              </div>
            </div>
            <div>{this.switchRender(this.state.event)}</div>
          </div>
          <NotificationContainer />
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Challenge);
