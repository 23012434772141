import React, { Component } from 'react';
import { ReactComponent as PenIcon } from '../static/img/ink.svg';
import { ReactComponent as EventIcon } from '../static/img/event.svg';
import { ReactComponent as BookIcon } from '../static/img/book.svg';
import { ReactComponent as ChatIcon } from '../static/img/chat.svg';
import { ReactComponent as PlayIcon } from '../static/img/play.svg';

class EventCounter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: 0,
      blogs: 0,
      posted: 0,
      forum: 0,
      video: 0,
    };
  }

  getBlogsCount() {
    const id = localStorage.getItem('studentId');
    fetch(`${process.env.REACT_APP_API_URL}/blogsCount/${id}`)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          posted: result.count,
        });
      });
  }

  getOtherEventsData() {
    const id = localStorage.getItem('studentId');
    fetch(`${process.env.REACT_APP_API_URL}/eventCount/${id}`)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.length > 0)
          this.setState({
            blogs: result.data[0].blogsReadCount,
            video: result.data[0].watched_courses.length,
          });
      });
  }

  getEventAttendedData() {
    const id = localStorage.getItem('studentId');
    fetch(`${process.env.REACT_APP_API_URL}/student/${id}/event-attended`)
      .then((response) => response.json())
      .then((result) => {
        if (result.count) {
          this.setState({ events: result.count });
        }
      });
  }

  getForumRepliesData() {
    const id = localStorage.getItem('studentId');
    fetch(`${process.env.REACT_APP_API_URL}/forumReplies/${id}`)
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          this.setState({ forum: result.data });
        }
      });
  }

  componentDidMount() {
    this.getBlogsCount();
    this.getOtherEventsData();
    this.getForumRepliesData();
    this.getEventAttendedData();
  }
  render() {
    const event = (title, count, icon, color, textColor) => (
      <div className="col " key={title}>
        <div className="d-flex justify-content-center">
          <div
            className="py-3 event mb-3 mb-xl-0"
            style={{
              backgroundColor: color,
              color: textColor,
              width: '140px',
            }}
          >
            <span>{title}</span>
            <div className="event-count">
              {count < 10 ? '0' + count : count}
            </div>
            <div className="d-flex align-content-center">{icon}</div>
          </div>
        </div>
      </div>
    );

    const events = [
      {
        title: 'Events Attended',
        count: this.state.events,
        icon: <EventIcon className="event-icon" />,
        color: '#d2dfee',
        text: '#2063aa',
      },
      {
        title: 'Blogs Read',
        count: this.state.blogs,
        icon: <BookIcon className="event-icon" />,
        color: '#dbe4cc',
        text: '#4b7804',
      },
      {
        title: 'Blogs Posted',
        count: this.state.posted,
        icon: <PenIcon className="event-icon" />,
        color: '#f3e6cc',
        text: '#c4852e',
      },
      {
        title: 'Forum Replies',
        count: this.state.forum,
        icon: <ChatIcon className="event-icon" />,
        color: '#f6dbe5',
        text: '#d34f7e',
      },
      {
        title: 'Video Completed',
        count: this.state.video,
        icon: <PlayIcon className="event-icon" />,
        color: '#dcd8e8',
        text: '#55448e',
      },
    ];

    return (
      <div className="event-container ">
        <div className="row">
          {events.map((data) =>
            event(data.title, data.count, data.icon, data.color, data.text)
          )}
        </div>
      </div>
    );
  }
}

export default EventCounter;
