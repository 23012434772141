import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class HomepageHeader extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header id="header">
          <div className="container-fluid">
            <div className="navbar">
              <div id="logo">
                {/* <div className="d-inline-flex align-items-center ">
                  <img
                    id="cbse-logo"
                    src="img/aisc-logo.png"
                    alt="AIFC"
                    className="mr-2 h-40 h-lg-40 "
                  />

                  <img
                    src="img/cbse.png"
                    alt="AIFC"
                    className="mr-2 mr-lg-2 cbse-logo h-40"
                  />
                  <img src={INTELLOGO} alt="AIFC" className=" h-40" />
                </div> */}
              </div>
              <div className="navigation-row">
                <nav id="navigation">
                  <button type="button" className="navbar-toggle">
                    <i className="fa fa-bars"></i>
                  </button>
                  <div className="nav-box navbar-collapse">
                    <ul
                      className="navigation-menu nav navbar-nav navbars d-md-flex align-items-center"
                      id="nav"
                    >
                      {/* <li className="bg-white py-1 px-2 d-flex align-items-center rounded-lg">
                        <img
                          src={KMVLOGO}
                          alt="AIFC"
                          className="mr-md-2 mr-lg-4 d-none d-md-inline-flex h-40"
                        />
                        <img
                          src={INTELLOGO}
                          alt="AIFC"
                          className="bg-white d-none d-md-inline-flex h-40"
                        />
                      </li> */}
                      <li
                        data-menuanchor="firstSection"
                        className="active home-link"
                      >
                        <a href="#firstSection">Home</a>
                      </li>
                      <li>
                        <a href="/about">About</a>
                      </li>
                      <li className="dropdown" style={{ marginTop: 0 }}>
                        <a
                          href="#"
                          className="nav-link page-scroll"
                          id="learningResourcesDropdownMenu"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Learning Resources
                        </a>
                        <div
                          className="dropdown-menu dropdown-menu-lg-right"
                          aria-labelledby="learningResourcesDropdownMenu"
                        >
                          <a
                            href="/learningResources#class-6-8"
                            className="dropdown-item"
                          >
                            Grade 6-8
                          </a>
                          <a
                            href="/learningResources#class-9"
                            className="dropdown-item"
                          >
                            Grade 9
                          </a>
                          <a
                            href="/learningResources#class-10"
                            className="dropdown-item"
                          >
                            Grade 10
                          </a>
                          <a
                            href="/learningResources#handbook"
                            className="dropdown-item"
                          >
                            Handbook
                          </a>
                          <a
                            href="/learningResources#sample-papers"
                            className="dropdown-item"
                          >
                            Sample Papers
                          </a>
                          <a
                            href="/learningResources#question-banks"
                            className="dropdown-item"
                          >
                            Question Bank
                          </a>
                        </div>
                      </li>
                      <li>
                        <a href="/exemplar-blogs">Exemplar Blogs</a>
                      </li>
                      <li className="mt-0 position-relative">
                        <a
                          href="#"
                          id="indiaAIDropdown"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Impact Festival
                        </a>
                        <div
                          className="dropdown-menu position-absolute left-50 tranform-translate-x-n50"
                          aria-labelledby="indiaAIDropdown"
                        >
                          <a
                            className="dropdown-item text-theme-stone"
                            href="/india-ai-impact-festival-2024"
                          >
                            2024
                          </a>
                        </div>
                      </li>
                      <li className="dropdown" style={{ marginTop: 0 }}>
                        <a
                          href="#"
                          id="lessonPlansDropdownMenu"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          AIoT Integration
                        </a>
                        <div
                          className="dropdown-menu dropdown-menu-lg-right"
                          aria-labelledby="lessonPlansDropdownMenu"
                        >
                          <a
                            href="/lesson-plans#grade-vi"
                            className="dropdown-item text-center"
                          >
                            Grade VI
                          </a>
                          <a
                            href="/lesson-plans#grade-vii"
                            className="dropdown-item text-center"
                          >
                            Grade VII
                          </a>
                          <a
                            href="/lesson-plans#grade-viii"
                            className="dropdown-item text-center"
                          >
                            Grade VIII
                          </a>
                          <a
                            href="/lesson-plans#grade-ix"
                            className="dropdown-item text-center"
                          >
                            Grade IX
                          </a>
                          <a
                            href="/lesson-plans#grade-x"
                            className="dropdown-item text-center"
                          >
                            Grade X
                          </a>
                        </div>
                      </li>
                      <li>
                        <a href="/AIProjects">AI Projects</a>
                      </li>
                      {/* <li data-menuanchor="secondSection" className="">
                        <a href="#secondSection">Why Join?</a>
                      </li>
                      <li data-menuanchor="thirdSection">
                        <a href="#thirdSection">Members</a>
                      </li>
                      <li data-menuanchor="fourthSection">
                        <a href="#fourthSection">Coming soon</a>
                      </li> */}
                      {/* <li data-menuanchor="fifthSection">
                        <a href="#fifthSection">Contact us</a>
                      </li> */}
                      <li>
                        <a href="https://ai-for-all.in" target="blank">
                          AI For All
                        </a>
                      </li>
                      <li>
                        <a className="nav-button" href="/login">
                          Login
                        </a>
                      </li>
                      <li className="ml-3">
                        <a className="nav-button" href="/signup">
                          Signup
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({}))(HomepageHeader);
