import React, { Component } from 'react';
import Headers from '../../components/Header/secondheader';
import Footer from '../../components/Footer/index';
import '../../index.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
  }
  setPath = (Id) => {
    const id = Id;
    localStorage.setItem('pathId', id);
    this.props.history.push(`/post/${Id}`);
  };
  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/forums/categories`, {
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            categories: result.categories,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { categories } = this.state;
    return (
      <div>
        <Headers />
        <div className="pt-265p pt-md-200p">
          <div className="limiter shadow  blog-container mt-0">
            <div className="row">
              <div className="col-md-6">
                <div className="section-heading mb-5">
                  <h2>Forums</h2>
                  <p className="sub-heading">
                    Please read the
                    <span className="primary">
                      Participation Guidelines
                    </span>{' '}
                    before interacting on the Forum. You can only Create a Topic
                    inside the following groups.
                    <span className="primary">
                      Connect with The Community Team
                    </span>
                    if you see someone violating the Code of Conduct.
                  </p>
                </div>
              </div>
            </div>
            <section className="p-t-50">
              <div className="">
                <div className="card">
                  <div className=" row  table-header-category mar-l-8 p-t-20 p-l-20">
                    <div className="all-select-categories">
                      <select
                        className="select-categories"
                        value={this.state.id}
                        onChange={(e) =>
                          this.props.history.push(`/post/${e.target.value}`)
                        }
                      >
                        {categories.map((categories, key) => (
                          <option
                            className="dropdown-item"
                            key={key}
                            value={categories.id}
                          >
                            {categories.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <button className='category-btn'>Categories</button> */}
                  </div>
                  {/* <div className = 'card-body'> */}
                  <div className="body">
                    {/* <h4 className="category-heading">Category</h4> */}
                    <div className="table-responsive">
                      <table className="table table-hover m-b-0">
                        <thead>
                          <tr>
                            {/* <th>Sl. No </th>                                     */}
                            <th>Category</th>
                            <th className="category-table">Posts</th>
                            <th>Views</th>
                          </tr>
                        </thead>
                        <tbody>
                          {categories.map((categories, key) => (
                            <tr className="category-table-row" key={key}>
                              {/* <td>{key+1}</td> */}
                              <td onClick={() => this.setPath(categories.id)}>
                                {categories.name}
                              </td>
                              <td>{categories.postCount}</td>
                              <td>{categories.viewCount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;

// import React from 'react'
// import { connect } from 'react-redux'
// import PropTypes from 'prop-types'
// import { setActiveCateg } from '../../actions/forumindex';
// import VisiblePostList from '../containers/VisiblePostList'
// import { Link } from 'react-router-dom'
// import CategoriesDropdown from './CategoriesDropdown'

// export class Home extends React.Component {

// 	componentDidMount() {
// 		this.props.activeCateg('SHOW_ALL')
// 	}

//   render() {
//     return (
//       <section className="page">
//         <div className="row">
//           <h4 className="page-title col s12">List of Posts</h4>
//           <p><Link className="text-carnation" to='/forum/new-post'>New Post</Link></p>

//           <VisiblePostList />
//         </div>
//       </section>
//     )
//   }
// }

// Home.propTypes = {
// 	activeCateg: PropTypes.func.isRequired,
// }

// const mapDispatchToProps = dispatch => ({
//   activeCateg: (category) => dispatch(setActiveCateg(category)),
// })

// export default connect(null,mapDispatchToProps)(Home)
