import React, { Component } from "react";
import WelcomeSection from "../HomepageSections/LandingSection";
import CommunitySection from "../HomepageSections/CommunitySection";
import MembersSection from "../HomepageSections/MembersSection";
import ContactSection from "../HomepageSections/ContactSection";
import HomepageHeader from "../Header/homepageHeader";
import Header from "../Header/header";
import ModalVideo from "react-modal-video";
import Popup from "reactjs-popup";
import styled from "styled-components";
import { isSubmissionOpen } from "../../utils/impactFestival";

const ImageSection = styled.div`
  aspect-ratio: 14 / 10;
  overflow: hidde̦n;
  width: 100%;

  @media (min-width: 768px) {
    aspect-ratio: 29 / 10;
  }
`;

const BannerImage = styled.img`
  object-fit: cover;
  object-position: left;
  height: 100%;
`;

const StyledText = styled.p`
  font-size: 1.4rem;
  color: #0067b4;
  font-weight: 600;
  line-height: 1.25;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

class HomeOld extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      count: "",
      showYoutubeVideo: false,
      isOpen: true,
    };
  }

  componentDidMount() {
    const link = document.createElement("link");
    link.href = "assets/css/homepageTheme.css";
    link.id = "homepageCss";
    link.rel = "stylesheet";
    document.head.appendChild(link);
  }

  render() {
    const _isSubmissionOpen = isSubmissionOpen();

    return (
      <>
        {_isSubmissionOpen &&
          localStorage.getItem("showLandingPagePopUp") === null && (
            <Popup
              trigger={<button style={{ display: "none" }}></button>}
              position="top center"
              open={this.state.isOpen}
              closeOnDocumentClick={false}
              overlayStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.80)",
                overflow: "hidden",
              }}
            >
              <div className="d-flex flex-column align-items-between h-100">
                <ImageSection className="d-flex flex-column justify-content-center">
                  <BannerImage src="images/india-ai-impact-festival-2024-banner.jpg" />
                </ImageSection>

                <div className="mt-3 d-flex justify-content-center flex-column flex-md-row p-2">
                  <div>
                    <div
                      onClick={() =>
                        (window.location.href =
                          "/india-ai-impact-festival-2024")
                      }
                      className="btn-primary rounded-10 py-2 px-4 text-white"
                      style={{ cursor: "pointer" }}
                    >
                      See details
                    </div>
                  </div>

                  <div className="mt-2 ml-md-3 mt-md-0">
                    <div
                      onClick={() => {
                        this.setState({ isOpen: false });
                        localStorage.setItem("showLandingPagePopUp", true);
                      }}
                      className="btn-secondary rounded-10 py-2 px-4"
                      style={{ cursor: "pointer" }}
                    >
                      Close
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
          )}

        <div className="preloader">
          <div className="preloader-bounce">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="video">
          <Header hideSubHeader={true} />
          {localStorage.setItem("count", this.state.count)}
          <link
            href="https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,700,900"
            rel="stylesheet"
          />

          <HomepageHeader />

          <link
            href="https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,700,900"
            rel="stylesheet"
          />
          <video
            autoPlay
            muted
            loop
            className="position-fixed"
            height="100%"
            width="100%"
          >
            <source src="assets/video/Background.m4v" type="video/mp4"></source>
          </video>
          <div id="fullpage">
            <WelcomeSection
              showVideo={(show) => this.setState({ showYoutubeVideo: show })}
            />
            <CommunitySection />
            <MembersSection />
            {/* <ComingSoonSection /> */}
            <ContactSection />
          </div>

          <div id="social-icons">
            <div className="text-right">
              <ul className="social-icons">
                <li>
                  <a
                    href="https://www.facebook.com/AI4Youth/"
                    title="AIFC Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/Ai4Youth?s=20"
                    title="AIFC Twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.secure.instagram.com/ai4.youth/"
                    title="AIFC Instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC5GefdX-CUrWuMJ7iHGc2nw"
                    title="AIFC Youtube"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <ModalVideo
            channel="youtube"
            autoplay="true"
            isOpen={this.state.showYoutubeVideo}
            width="930px"
            height="528px"
            videoId="yyT5zSjx6lA"
            onClose={() => this.setState({ showYoutubeVideo: false })}
          />
        </div>
      </>
    );
  }
}

export default HomeOld;
