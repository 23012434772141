import React, { Component } from 'react';
import Header from '../Header/header';
import SecondHeader from '../Header/secondheader';
import Footer from '../Footer/index';
import image from '../../static/img/1.jpeg';
import ModalVideo from 'react-modal-video';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

class AIProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showYoutubeVideo: false,
      youtubeVideoUrl: '',
      readMore: -1,
      projects: [],
    };
  }

  getResources() {
    fetch(`${process.env.REACT_APP_API_URL}/ai-projects`)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          projects: result.projects,
        });
      });
  }

  componentDidMount() {
    this.getResources();
  }
  render() {
    const { projects } = this.state;
    projects.sort((a, b) => a.order - b.order);

    const listItem = (
      badges,
      title,
      studentDetails,
      description,
      video_url,
      thumbnail_url,
      index
    ) => (
      <div class="col-sm-6">
        <div class="card border-0 shadow AI-card">
          <div class="card-body">
            <div className="row">
              <div className="col-sm-7 pr-0">
                <div className="mb-1">
                  {badges.map((badge) => (
                    <span
                      class={`badge badge-pill ${
                        badge === 'CV'
                          ? 'badge-success'
                          : badge === 'DATA'
                          ? 'badge-danger'
                          : 'badge-primary'
                      }`}
                    >
                      {badge}
                    </span>
                  ))}
                </div>
                <h5 className="mb-1">{title}</h5>
                <h6 className="mt-0 student-data">
                  {this.state.readMore === index
                    ? studentDetails.map((student, studentIndex) => {
                        return (
                          <>
                            {student.name}, {student.school}{' '}
                            {studentIndex === studentDetails.length - 1 ? (
                              <MdKeyboardArrowUp
                                className="cursor-pointer"
                                onClick={() => this.setState({ readMore: -1 })}
                              />
                            ) : (
                              ''
                            )}
                          </>
                        );
                      })
                    : studentDetails.map((student, index) => {
                        return (
                          <>
                            {student.name}
                            {index === studentDetails.length - 1 ? '' : ', '}
                          </>
                        );
                      })}
                  {this.state.readMore === index ? null : (
                    <MdKeyboardArrowDown
                      className="cursor-pointer"
                      onClick={() => this.setState({ readMore: index })}
                    />
                  )}
                </h6>
                <p className="mb-0 ">{description}</p>
              </div>
              <div className="col-sm-5 d-flex justify-content-center h-100">
                <img
                  src={thumbnail_url}
                  onClick={() =>
                    this.setState({
                      showYoutubeVideo: true,
                      youtubeVideoUrl: video_url,
                    })
                  }
                  alt=""
                  className="AI-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {localStorage.getItem('jwtToken') == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}
        <div className="pt-265p pt-md-200p">
          <div className="mx-5 mb-5">
            <div class="container">
              <h2 class="pb-3 border-bottom mb-4 mx-4">AI Projects</h2>
              <div class="row">
                {projects.map((item, index) =>
                  listItem(
                    item.badges,
                    item.title,
                    item.students,
                    item.description,
                    item.videoURL,
                    `https://img.youtube.com/vi/${item.videoURL}/maxresdefault.jpg`,
                    index
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          autoplay="true"
          isOpen={this.state.showYoutubeVideo}
          width="930px"
          height="528px"
          videoId={this.state.youtubeVideoUrl}
          onClose={() => this.setState({ showYoutubeVideo: false })}
        />
        <Footer />
      </React.Fragment>
    );
  }
}

export default AIProjects;
