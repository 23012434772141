import React, { Component } from 'react';

import Header from '../components/Header/header';
import SecondHeader from '../components/Header/secondheader';
import FaqSection from '../components/Faq/faq';
import FooterSection from '../components/Footer';

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        {localStorage.getItem('jwtToken') == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}

        <div className="main">
          <FaqSection />
        </div>
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default Theme;
