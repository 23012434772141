import React, { Component } from 'react';
import Slider from './Slider';
import Card from './Card';
import { Link } from 'react-router-dom';

class LatestBlogs extends Component {
  render() {
    const data = this.props.data;

    const latestData = data ? data : [];
    const footer = (creator, id, profilePic) => (
      <div className="d-flex" style={{ width: '100%' }}>
        <div style={{ fontSize: '12px', width: '100%' }} className="d-flex p-2">
          <img
            className="img blog-profile-pic mr-2"
            src={profilePic}
            alt="User-Icon"
          />

          <span>{creator}</span>
        </div>
        <div className="d-flex justify-content-end" style={{ width: '100%' }}>
          <Link
            to={`singleblog/${id}`}
            className="btn btn-primary enroll-button py-2"
          >
            Read full blog
          </Link>
        </div>
      </div>
    );
    return (
      <div className="latest-blogs p-4 m-3 mt-4">
        <div className="d-flex align-items-center px-2">
          <h2>BLOGS PUBLISHED RECENTLY</h2>
          <Link to="/blog" className="btn btn-primary link-button ml-auto ">
            View all blogs
          </Link>
        </div>
        <Slider buttonMoveAway={true}>
          {latestData.map((data) => (
            <Card data={data} key={data.id} footer={footer} />
          ))}
        </Slider>
      </div>
    );
  }
}

export default LatestBlogs;
