import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class CommentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (event) => {
    const { location } = this.props;
    event.preventDefault();
    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/forums/posts/${location.pathname.substring(13)}/comments`,
      {
        method: 'POST',

        headers: {
          'x-access-token': localStorage.getItem('jwtToken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },

        body: JSON.stringify({
          body: event.target.body.value,
          timestamp: event.target.timestamp.value,
          student_id: localStorage.getItem('studentId'),
        }),
      }
    )
      .then((res) => res.json())
      .then((responseJson) => {
        window.location.reload();
      });
  };

  render() {
    const timestamp = Date.now();
    return (
      <div>
        <form className="col s12" onSubmit={this.handleSubmit}>
          <input type="hidden" name="timestamp" defaultValue={timestamp} />
          <div className="input-field col-md-12">
            <label className="active">Comment</label>
            <textarea
              id="description"
              className="form-control"
              name="body"
              required
              rows="5"
            />
          </div>

          {/* <div className="input-field col-sm-4">
            <label className="active">Author</label>
            <input
              id="title"
              type="text"
              className="form-control"
              name="author"
              required
            />
          </div> */}

          <div className="input-field col-m-12 pt-50 pb-50">
            <button
              type="button"
              className="btn-blue-border"
              onClick={() => this.props.history.goBack()}
            >
              Cancel
            </button>{' '}
            &nbsp;
            <button type="submit" className="btn-blue-border">
              Add Comment
            </button>
          </div>
        </form>
      </div>
    );
  }
}

// export default CommentCard;
export default withRouter(CommentCard);
