import React from 'react';
import { connect } from 'react-redux';
import { changePassword } from '../../actions/index';
import Headers from '../Header/header';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      hero: {},
      password: '',
      confirmPassword: '',
      token: location.pathname.substring(16),
      disableBtn: false,
      btnText: 'Change Password',
      passwordUpdated: false,
      passwordConfirmed: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ passwordConfirmed: false });
      return;
    }

    this.setState({
      disableBtn: true,
      btnText: 'Changing...',
    });

    fetch(`${process.env.REACT_APP_API_URL}/users/changepass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: this.state.password,
        token: this.state.token,
      }),
    })
      .then((res) => res.json())
      .then((data) => this.setState({ passwordUpdated: true }));

    // // get action
    const changePasswordAction = changePassword(this.state);

    // // Dispatch the contact from data
    this.props.dispatch(changePasswordAction);

    // // added delay to change button text to previous
    setTimeout(
      function () {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText('Change Password');

        // get action again to update state
        const changePasswordAction = changePassword(this.state);

        // Dispatch the contact from data
        this.props.dispatch(changePasswordAction);

        // clear form data
        this.setState({
          password: '',
          token: '',
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <Headers />
        <section className="page-change-password pt-240 pt-md-200">
          <div className="container">
            <div className="row align-items-center justify-content-between pt-sm-5 pt-md-5 pt-lg-0 login-container">
              <div className="col-md-12 col-lg-5 login-inner-container">
                <div className="card login-signup-card shadow-lg mb-0">
                  <div className="card-body px-md-5 py-5">
                    <h1 className="mb-1 page-title">Change Password</h1>
                    {this.state.passwordUpdated ? (
                      <div className="mt-4">
                        <div className="alert alert-success">
                          Your password has been updated successfully.
                        </div>
                        <small className="text-muted text-carnation pl-1">
                          Use your new password to{' '}
                          <a
                            className="text-carnation font-weight-bold"
                            href="/login"
                          >
                            log in
                          </a>
                        </small>
                      </div>
                    ) : (
                      <div>
                        <p className="text-muted mb-5">
                          Enter your new password
                        </p>
                        <form
                          className="login-signup-form"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="form-group">
                            <label className="pb-1">New Password</label>
                            <div className="input-group input-group-merge">
                              <input
                                value={this.state.password}
                                onChange={(e) =>
                                  this.handleFormValueChange('password', e)
                                }
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="New password"
                                required
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="pb-1">Confirm Password</label>
                            <div className="input-group input-group-merge">
                              <input
                                value={this.state.confirmPassword}
                                onChange={(e) =>
                                  this.handleFormValueChange(
                                    'confirmPassword',
                                    e
                                  )
                                }
                                type="password"
                                name="confirmPassword"
                                className="form-control"
                                placeholder="Confirm password"
                                required
                              />
                            </div>
                            {!this.state.passwordConfirmed && (
                              <div class="text-danger">
                                Password does not match
                              </div>
                            )}
                          </div>
                          <div>
                            <button
                              className="btn-theme-primary mt-4 mb-3"
                              id="signIn"
                              disabled={this.state.disableBtn}
                            >
                              {this.state.btnText}
                            </button>
                          </div>

                          <p>
                            <small className="text-muted text-carnation">
                              Remember your password?{' '}
                              <a className="text-carnation" href="/login">
                                Log in
                              </a>
                            </small>
                          </p>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  state,
}))(ChangePassword);
