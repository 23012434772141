import React, { Component, Fragment } from 'react';
import SignleHeader from '../Header/secondheader';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Footer from '../../components/Footer/index';
import userPic from '../../static/img/aifc-logo-vertical.png';
import returnIcon from '../../static/img/return.png';
import { Link } from 'react-router-dom';

import 'react-notifications/lib/notifications.css';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { FaCalendarAlt, FaExternalLinkAlt, FaRegClock } from 'react-icons/fa';
import { MdLink, MdLocationOn } from 'react-icons/md';
import DOMPurify from 'dompurify';

class single_event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: null,
      participantsCount: [],
    };
  }

  createNotification = (type) => {
    switch (type) {
      case 'info':
        NotificationManager.info('Info message');
        break;
      case 'success':
        NotificationManager.success(
          'We will see you there!',
          'Your RSVP is confirmed!',
          3000
        );
        break;
      case 'warning':
        NotificationManager.warning(
          'Warning message',
          'Close after 3000ms',
          3000
        );
        break;
      case 'error':
        NotificationManager.error(
          'Uh-oh! It would be great if you come.',
          'Your RSVP has been canceled.',
          3000
        );
        break;
    }
  };

  rsvpEvent(id) {
    const { location } = this.props;
    const sid = localStorage.getItem('studentId');

    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/participate/${sid}/event${location.pathname.replace(
        /\/single_event/,
        ''
      )}`,
      {
        headers: {
          'x-access-token': localStorage.getItem('jwtToken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
        this.createNotification('success');
        this.getEventData();
        this.getEventParticipants();
      });
  }

  unRsvpEvent(id) {
    const { location } = this.props;
    const sid = localStorage.getItem('studentId');
    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/unparticipate/${sid}/event${location.pathname.replace(
        /\/single_event/,
        ''
      )}`,
      {
        headers: {
          'x-access-token': localStorage.getItem('jwtToken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
        this.createNotification('error');
        this.getEventData();
        this.getEventParticipants();
      });
  }

  getEventParticipants = () => {
    const { location } = this.props;
    fetch(
      `${process.env.REACT_APP_API_URL}/events${location.pathname.replace(
        /\/single_event/,
        ''
      )}/participants-count`,
      {
        headers: { 'x-access-token': localStorage.getItem('jwtToken') },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            participantsCount: result.count,
          });
        },
        (error) => {
          this.setState({
            error,
          });
        }
      );
  };

  getEventData = () => {
    const { location } = this.props;
    const sid = localStorage.getItem('studentId');
    fetch(
      `${process.env.REACT_APP_API_URL}/events${location.pathname.replace(
        /\/single_event/,
        ''
      )}?userId=${sid}`,
      {
        headers: {
          'x-access-token': localStorage.getItem('jwtToken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            events: result.events,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  };

  componentDidMount() {
    this.getEventData();
    this.getEventParticipants();
  }

  render() {
    const currentDate = new Date();
    const { events } = this.state;
    const { participantsCount } = this.state;
    if (events && events.length === 0) {
      return <Redirect to="/404page" />;
    }
    return (
      <div>
        <SignleHeader />
        {events &&
          events.map((data, index) => (
            <section
              className="our-blog-section gray-light-bg single-blog-container"
              key={`event_${data.id}`}
            >
              <div className="blog-container container mx-auto shadow rounded">
                <div className="">
                  <Link to="/event">
                    <div
                      className="text-carnation"
                      style={{ fontSize: '18px', fontWeight: 'bold' }}
                    >
                      <img
                        className="return-icon"
                        src={returnIcon}
                        alt="icon"
                      />
                      <p className="return-label"> Go Back</p>
                    </div>
                  </Link>
                  <div className="row pb-15 pt-15 d-flex justify-content-between">
                    <div className="col-md-8 text-left">
                      <h2 className="text-left">{data.title}</h2>
                    </div>
                    {moment(data.end_time).unix() >
                      moment(currentDate).unix() && (
                      <div className="col-md-4 no-padding text-right">
                        {data.isRegistered === true ? (
                          <div>
                            <span className="mr-2 text-carnation">
                              Your RSVP is confirmed!
                            </span>
                            <button
                              className="btn-blue"
                              onClick={() => {
                                this.unRsvpEvent(data.id);
                              }}
                            >
                              Enrolled
                            </button>
                          </div>
                        ) : (
                          <button
                            className="btn-blue"
                            onClick={() => {
                              this.rsvpEvent(data.id);
                            }}
                          >
                            Enroll
                          </button>
                        )}
                        <p className="text text-muted mx-3">
                          {participantsCount} facilitators have enrolled
                        </p>
                      </div>
                    )}
                  </div>

                  <div
                    className="row"
                    style={{ justifyContent: 'space-evenly' }}
                  >
                    <div className="col-md-8" key={index}>
                      <div className="card border-0 shadow-sm event-card mb-xl-5">
                        <img
                          src={data.cover}
                          className="card-img-top position-relative"
                          alt="event"
                        />
                        <div className="card-content">
                          <div className="event-title no-mb d-flex">
                            <FaCalendarAlt className="mr-2 mt-1" />
                            {moment(data.start_time).format('LL hh:mm A')}
                            <span className="font-weight-normal">
                              &nbsp;to&nbsp;
                            </span>
                            {moment(data.end_time).format('LL hh:mm A')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-event-card card border-0 shadow-sm event-card padding-25 bdr-20">
                        <div className="event-tags mar-b-25">
                          <h5>Tags</h5>
                          <div className="post-tags">
                            {data.tags.map((tag, indext) => (
                              <span
                                key={indext}
                                className="category badge badge-pill badge-primary"
                              >
                                {tag}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="mar-b-25 mar-b-10">
                          {data.location.locationName && (
                            <>
                              <h5> Location </h5>
                              <div className="loc-event-container mar-b-10">
                                <p className="return-label mrb-0">
                                  <MdLocationOn className="mr-1" />
                                  {data.location.locationName}
                                </p>
                              </div>
                            </>
                          )}
                          {data.url && (
                            <a href={data.url} className="text-carnation">
                              <MdLink className="mr-1" />
                              {data.url_label ? data.url_label : 'Meeting Link'}
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="single-event-card card border-0 shadow-sm event-card padding-25 bdr-20 mar-b-25">
                        <h5>Hosted By</h5>
                        <div className="">
                          <img
                            className="profile-icon display-inline"
                            src={userPic}
                            alt="userimg"
                          />
                          <p className="display-inline">{data.event_creator}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ justifyContent: 'space-evenly' }}>
                  <div className="col-md-8">
                    <div className="event-desc">
                      <h4>Description</h4>
                      <p
                        className="ws-pw"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data.desc),
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className="col-md-4" key={index}></div>
                </div>
              </div>
              <NotificationContainer />
            </section>
          ))}
        <Footer />
      </div>
    );
  }
}

export default single_event;
