let token = localStorage.token;

if (!token) token = localStorage.token = Math.random().toString(36).substr(-8);

const headers = {
  Accept: 'application/json',
  Authorization: token,
};

export const getPosts = () =>
  fetch(`${process.env.REACT_APP_API_URL}/forums/posts`, { headers })
    .then((res) => res.json())
    .then((data) => data);

export const getPostDetail = (postId) =>
  fetch(`${process.env.REACT_APP_API_URL}/forums/posts/${postId}`, { headers })
    .then((res) => res.json())
    .then((data) => data);

export const removePost = (postId) =>
  fetch(`${process.env.REACT_APP_API_URL}/forums:8080/posts/${postId}`, {
    method: 'DELETE',
    headers,
  })
    .then((res) => res.json())
    .then((data) => data);

export const createPost = (post) =>
  fetch(`${process.env.REACT_APP_API_URL}/forums/posts`, {
    method: 'POST',
    headers: {
      ...headers,
      'x-access-token': localStorage.getItem('jwtToken'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(post),
  })
    .then((res) => res.json())
    .then((data) => {
      // eslint-disable-next-line no-undef
      if (data.message === 'post successfully registered') {
        window.location.href = `/post/${localStorage.getItem('pathId')}`;
      } else {
        alert('something wrong!');
      }
    });

export const editPost = (postId, body) =>
  fetch(`${process.env.REACT_APP_API_URL}/forums/posts/${postId}`, {
    method: 'PUT',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => data);

export const voteOnPost = (postId, vote) =>
  fetch(`/posts/${postId}`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ option: vote }),
  })
    .then((res) => res.json())
    .then((data) => data);

export const getCommentsByPost = (postId) =>
  fetch(`/posts/${postId}/comments`, { headers })
    .then((res) => res.json())
    .then((data) => data);

export const createComment = (body) =>
  fetch(`/comments`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());

export const editComment = (commentId, body) =>
  fetch(`/comments/${commentId}`, {
    method: 'PUT',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());

export const getCommentSingle = (id) =>
  fetch(`/comments/${id}`, { headers })
    .then((res) => res.json())
    .then((data) => data);

export const voteOnComment = (commentId, vote) =>
  fetch(`/comments/${commentId}`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ option: vote }),
  })
    .then((res) => res.json())
    .then((data) => data);

export const removeComment = (commentId) =>
  fetch(`/comments/${commentId}`, { method: 'DELETE', headers })
    .then((res) => res.json())
    .then((data) => data);

export const getCategories = () =>
  fetch(`${process.env.REACT_APP_API_URL}/forums/categories`, { headers })
    .then((res) => res.json())
    .then((data) => data.categories);

export const getCategoriesByPosts = (categoryId) =>
  fetch(`${process.env.REACT_APP_API_URL}/forums/${categoryId}/posts`, {
    headers,
  })
    .then((res) => res.json())
    .then((data) => data);

export const getTags = () =>
  fetch(`${process.env.REACT_APP_API_URL}/forums/tags`, { headers })
    .then((res) => res.json())
    .then((data) => data.tags);
