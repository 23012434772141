import React, { Component } from 'react';

import Header from '../Header/header';
import SecondHeader from '../Header/secondheader';
import Footer from '../Footer/index';
import { FiExternalLink } from 'react-icons/fi';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import slugify from 'slugify';

class LearningResources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      collapsed: [],
      types: [],
      classes: [],
    };
  }

  getResources() {
    fetch(`${process.env.REACT_APP_API_URL}/learning-resources`)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          resources: result.resources,
        });
      });
  }
  getTypes() {
    fetch(`${process.env.REACT_APP_API_URL}/learning-resources-types`)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          types: result.resources,
        });
      });
  }

  getClasses() {
    fetch(`${process.env.REACT_APP_API_URL}/learning-resources-classes`)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          classes: result.resources,
        });
      });
  }

  getTypeTitle = (typeId) => {
    const { types } = this.state;
    const matchedType = types.find((type) => type._id === typeId);
    return matchedType ? matchedType.title : '';
  };

  getGradeTitle = (gradeId) => {
    const { classes } = this.state;
    const matchedGrade = classes.find((grade) => grade._id === gradeId);
    return matchedGrade ? matchedGrade.title : '';
  };

  componentDidMount() {
    this.getResources();
    this.getTypes();
    this.getClasses();
  }

  render() {
    const listItem = (title, description, link, linkLabel) => (
      <div className="col-md-6 mt-3">
        <div className="mtbl-3 h-100">
          <div className="list-group-item list-group-item-action flex-column align-items-start h-100">
            <div className="d-flex w-100 justify-content-between">
              <h4 className="mb-1">{title}</h4>
              <a
                href={link}
                className="btn btn-primary h-100 d-flex align-items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkLabel ? linkLabel : 'View'}{' '}
                <FiExternalLink className="view-icon ml-1" />
              </a>
            </div>
            <p className="mb-1 text-justify">{description}</p>
          </div>
        </div>
      </div>
    );

    // Get all unique grades for the resources like 6, 9 or 10
    const getGrades = this.state.resources.map((resource) => resource.standard);
    const uniqueGrades = [...new Set(getGrades)];

    // Get All types of resources like Deep dive and academic
    const getTypes = this.state.resources.map((resource) => resource.type);
    const uniqueTypes = [...new Set(getTypes)];
    uniqueTypes.sort();

    // Create an array of all the resources of a grade
    const gradeList = uniqueGrades.map((grade) => {
      let learningResourcesForGrade = this.state.resources.filter(
        (resource) => resource.standard === grade
      );

      // Separate the data into its types
      const type = uniqueTypes.map((resouceType) => {
        let uniqueTypeData = learningResourcesForGrade.filter(
          (resource) => resource.type === resouceType
        );

        // sort according to order
        uniqueTypeData.sort((a, b) => a.order - b.order);

        uniqueTypeData = uniqueTypeData.map((resource) => ({
          title: resource.title,
          description: resource.description,
          link: resource.link,
          linkLabel: resource.linkLabel,
        }));

        return {
          title: resouceType,
          data: uniqueTypeData,
        };
      });

      return {
        title: grade,
        type,
      };
    });

    const list = (
      <div className="accordion w-100">
        {gradeList.map((grade, index) => {
          return (
            <div
              key={`${grade}-${index}`}
              className="blog-container container mx-auto shadow rounded"
              id={slugify(grade.title, { lower: true })}
              style={{ scrollMarginTop: '160px' }}
            >
              <div>
                <div className="mb-0 ">
                  <a
                    onClick={() =>
                      this.setState({
                        collapsed: this.state.collapsed.includes(index)
                          ? this.state.collapsed.filter(
                              (item) => item !== index
                            )
                          : [...this.state.collapsed, index],
                      })
                    }
                    data-toggle="collapse"
                    href={`#grade${index}`}
                    role="button"
                    aria-expanded="false"
                    aria-controls={`grade${index}`}
                  >
                    <div
                      className={`${
                        this.state.collapsed.includes(index)
                          ? ''
                          : 'border-bottom-3 border-oslo-gray'
                      } d-flex mx-4`}
                    >
                      <h2 className="px-0 text-left pt-3">
                        {this.getGradeTitle(grade.title)}
                      </h2>
                      <div className="ml-auto p-3">
                        {this.state.collapsed.includes(index) ? (
                          <AiOutlineDown className="icon w-40 h-40" />
                        ) : (
                          <AiOutlineUp className="icon w-40 h-40" />
                        )}
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div id={`grade${index}`} class="collapse show">
                <div class="card-body pt-0">
                  {grade.type
                    .filter((data) => data.data.length > 0)
                    .map((data, gradeDataIndex) => (
                      <div key={`grade${index}${gradeDataIndex}`}>
                        <h3 className="mt-4 mb-0 pl-3 text-left">
                          {this.getTypeTitle(data.title)}
                        </h3>
                        <div className="row">
                          {data.data.map((item) =>
                            listItem(
                              item.title,
                              item.description,
                              item.link,
                              item.linkLabel
                            )
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );

    return (
      <React.Fragment>
        {localStorage.getItem('jwtToken') == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}
        <div className="pt-265p pt-md-200p">
          <div className="mx-5 mb-5">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h2 className="pb-3 border-bottom mb-4">
                    Learning Resources
                  </h2>
                  <p className="lead">
                    Level up your AI game with the Learning Resources curated
                    for you!
                  </p>
                  <div className="row">{list}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default LearningResources;
