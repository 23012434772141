import React from "react";

const IndiaAIFestivalTheme = (props) => {
  return (
    <div className="mb-3 mb-md-5">
      <h2 className="text-center mt-4">Timelines</h2>
      <div className="mt-3 mt-md-5">
        <img src="/images/timeline.png" className="w-100" />
      </div>
    </div >
  );
};

export default IndiaAIFestivalTheme;
