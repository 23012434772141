import React, { Component } from 'react';

import WelcomeSection from '../components/HomepageSections/LandingSection';
import CommunitySection from '../components/HomepageSections/CommunitySection';
import MembersSection from '../components/HomepageSections/MembersSection';
import ContactSection from '../components/HomepageSections/ContactSection';
import HomepageHeader from '../components/Header/homepageHeader';
import Header from '../components/Header/header';
import ModalVideo from 'react-modal-video';
// import ComingSoonSection from "../components/HomepageSections/ComingSoonSection";

class Theme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      count: '',
      showYoutubeVideo: false,
    };
  }
  componentWillMount() {
    const link = document.createElement('link');
    link.href = 'assets/css/homepageTheme.css';
    link.id = 'homepageCss';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/increasesitecount`, {
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
      },
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          count: result.data[0].siteCount,
        });
      });
  }

  componentWillUnmount() {
    const link = document.querySelector('#homepageCss');
    document.head.removeChild(link);
  }

  render() {
    return (
      <div id="video">
        <Header hideSubHeader />
        {localStorage.setItem('count', this.state.count)}
        <link
          href="https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,700,900"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,700,900"
          rel="stylesheet"
        />
        <div className="preloader">
          <div className="preloader-bounce">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <video
          autoPlay
          muted
          loop
          className="position-fixed"
          height="100%"
          width="100%"
        >
          <source src="assets/video/Background.m4v" type="video/mp4"></source>
        </video>
        <div id="fullpage">
          <section className="vertical-scrolling animated-row">
            <div className="section-inner">
              <div className="welcome-box mt-5">
                <h1 className="welcome-title animate" data-animate="fadeInUp">
                  AI Facilitator Community
                </h1>
                <h2
                  className="animate f-weight-600 fz-30 fz-lg-50"
                  data-animate="fadeInUp"
                  style={{ marginTop: '50px' }}
                >
                  Coming Soon
                </h2>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Theme;
