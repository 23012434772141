import React from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authAction';

class SsoLogout extends React.Component {
  constructor(props) {
    super(props);
    this.props.logoutUser();
    window.location = '/';
  }

  render() {
    return 'Logging out...';
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    error: state.authFail,
  };
};

export default connect(mapStateToProps, { logoutUser })(SsoLogout);
