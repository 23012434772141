import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
//import { composeWithDevTools } from "redux-devtools-extension";
import IntelStudentApp from './reducers';
import Routes from './routers';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from '../src/actions/authAction';
import ReactGA from 'react-ga';
import { LanguageProvider } from "./contexts/LanguageContext";

// const trackingId = process.env.GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize('G-Q6WB83ZB53');

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// create store
const store = createStore(
  IntelStudentApp,
  // composeWithDevTools(),
  composeEnhancers(applyMiddleware(...middleware))
  // other store enhancers if any
);

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = './login';
  }
}

function App() {
  return (
    <Provider store={store}>
      <LanguageProvider>
        <Routes />
      </LanguageProvider>
    </Provider>
  );
}

export default App;
