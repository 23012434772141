import React, { Component } from 'react';
import Header from '../Header/header';
import SecondHeader from '../Header/secondheader';
import Footer from '../Footer/index';
import moment from 'moment';

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      attended: false,
    };
  }

  getEvent() {
    const studentId = localStorage.getItem('studentId');
    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/event/link${window.location.pathname.replace(/\/mark-attendance/, '')}`
    )
      .then((response) => response.json())
      .then((result) => {
        const { event } = result;
        const attended =
          event &&
          event.attended &&
          event.attended.includes(parseInt(studentId));
        this.setState({
          event: result.event,
          attended,
        });
      });
  }

  markAttendance() {
    const studentId = localStorage.getItem('studentId');
    fetch(`${process.env.REACT_APP_API_URL}/event/mark-attendance`, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventId: this.state.event.id,
        studentId,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          attended: result.attended,
        });
      });
  }

  componentDidMount() {
    this.getEvent();
  }

  eventExpired() {
    const { event } = this.state;

    if (!event) {
      return false;
    }

    const secondsToExpire = 60 * 60 * 6; // 6 hours.
    const endTime = moment(event.end_time);
    const diffSeconds = moment().diff(endTime, 'seconds');
    return diffSeconds > secondsToExpire;
  }

  render() {
    const studentId = localStorage.getItem('studentId');
    const { attended, event } = this.state;
    return (
      <React.Fragment>
        {localStorage.getItem('jwtToken') == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}
        <section className="pt-265p gray-light-bg pb-5">
          <div className="w-25 mx-auto shadow rounded p-5">
            {attended ? (
              <h4 className="row mx-0 mb-3 text-center">
                Your attendance is marked
              </h4>
            ) : (
              <>
                {this.state.event === null ? (
                  <h4 className="row mx-0 mb-3 text-center">Invalid Link!</h4>
                ) : (
                  <>
                    {this.eventExpired() ? (
                      <h4 className="row mx-0 mb-3 text-center">
                        Link has expired!
                      </h4>
                    ) : (
                      <>
                        <h4 className="row mx-0 mb-3 text-center">
                          Mark attendance for the event "
                          {this.state.event?.title}"
                        </h4>
                        <div className="text-center">
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => this.markAttendance()}
                          >
                            Mark Attendance
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Attendance;
