import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaPencilAlt } from 'react-icons/fa';

class Bio extends Component {
  render() {
    const { data } = this.props;
    const profileData = data[0];

    return (
      <div className="bio-container p-3 m-3 shadow">
        {profileData ? (
          <div className="row mx-0">
            <div className="col-md-6 main-intro pb-2 pb-md-0">
              <div className="row">
                <div className="col-md-4 align-content-center">
                  <div className="d-flex align-content-center justify-content-center">
                    <img
                      className="img-user rounded-circle"
                      src={profileData.profile_pic}
                      alt="userimg"
                    />
                  </div>
                </div>
                <div className="col-md-8 no-padding text-left">
                  <div className="d-flex align-items-center h-100">
                    <div>
                      <h4 className="text-left">{profileData.name}</h4>
                      <p>{profileData.school}</p>
                      <p>{profileData.location}</p>
                      <p className="uid">UID: {profileData.uid}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 pt-2 pb-3">
              <div className=" d-flex ">
                <h5>Bio</h5>
                <Link to="/profile" className=" ml-auto">
                  <FaPencilAlt style={{ color: '#717375' }} />
                </Link>
              </div>

              <p>{profileData.bio}</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Bio;
