/* eslint-disable default-case */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../index.css';
import moment from 'moment';
import linkIcon from '../../static/img/link_icon.png';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { AiOutlinePlus } from 'react-icons/ai';
// require("bootstrap/less/bootstrap.less");

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      myblogs: [],
      myblog: 'all',
      bgcolor: '',
      color: '',
      pagemyblog: [],
      perPage: 9,
      currentPage: 1,
      currentblogPage: 1,
      pageCount: 0,
      quotesCount: 0,
      offset: 0,
      blogpageCount: 0,
      activepage: 1,
      offsetblog: 0,
      searchoffset: 0,
      postData: [],
      searchData: [],
      blogData: [],
      selectedTag: null,
    };
  }
  handlePageChange(pageNumber) {
    // this.state.perPage=Math.ceil(pageNumber/3)
    this.setState({
      activePage: pageNumber,
      perPage: Math.ceil(pageNumber / 3),
    });
  }
  componentDidMount() {
    this.viewAllData();
    this.myBlogsData();
    this.getAllBlogTags();
  }

  getAllBlogTags = () => {
    fetch(`${process.env.REACT_APP_API_URL}/forums/tags`, {
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          tags: data.tags,
        });
      });
  };

  getCard(data, index) {
    return (
      <React.Fragment key={index}>
        <div className="col-md-4 d-flex pb-3">
          <div
            className={'mx-auto single-blog-card card border shadow-sm'}
            style={{ width: '300px' }}
          >
            <div className="blogs-tag">
              {data.tags !== null
                ? data.tags.slice(0, 3).map((tags, key) => (
                    <span
                      key={key}
                      className="mar-r-8 badge badge-pill badge-primary"
                    >
                      {tags.value}
                    </span>
                  ))
                : null}
            </div>
            <img
              src={data.cover}
              className="card-img-top position-relative"
              alt="blog"
              style={{ height: '160px' }}
              onClick={() => this.props.history.push(`singleblog/${data.id}`)}
            />

            <div
              className="card-body"
              style={{
                color: '#435370',
                padding: '14px',
              }}
            >
              <h3
                style={{
                  cursor: 'pointer',
                  minHeight: 'unset',
                  fontSize: '18px',
                  fontWeight: '800',
                }}
                className="h5 card-title"
                onClick={() => this.props.history.push(`singleblog/${data.id}`)}
              >
                {data.title.length > 80
                  ? data.title.substring(0, 80) + '...'
                  : data.title}
              </h3>
              <p
                className="card-text"
                style={{ lineHeight: '1.35', color: 'rgb(60, 76, 107)' }}
              >
                {data.excerpt.length > 120
                  ? data.excerpt.substring(0, 120) + '...'
                  : data.excerpt}
              </p>
            </div>

            {data.student_data &&
              data.student_data.map((student_data, index) => (
                <div className="d-flex" style={{ width: '100%' }} key={index}>
                  <div
                    style={{ fontSize: '12px', width: '100%' }}
                    className="d-flex p-2"
                  >
                    <img
                      className="img blog-profile-pic mx-2"
                      src={student_data.profile_pic}
                      alt="User-Icon"
                    />

                    <span>{student_data.first_name}</span>
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ width: '100%' }}
                  >
                    <a
                      href={`/singleblog/${data.id}`}
                      className="btn btn-primary enroll-button py-2 text-white"
                    >
                      Read full blog
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </React.Fragment>
    );
  }

  viewAllData = () => {
    let studentId = localStorage.getItem('studentId');
    fetch(`${process.env.REACT_APP_API_URL}/blogs?student_id=${studentId}`, {
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const blogList = data.blogs;
        const blog = blogList.slice(
          this.state.offsetblog,
          this.state.offsetblog + this.state.perPage
        );

        const blogData = blog.map((data, index) => this.getCard(data, index));
        this.setState({
          blogpageCount: Math.ceil(blogList.length / this.state.perPage),
          blogData,
          first_time_blog: data.first_time_blog,
        });
      });
  };

  handleBlogPageClick = (e) => {
    const selectedblogPage = e.selected;
    const offsetblog = selectedblogPage * this.state.perPage;

    this.setState(
      {
        currentblogPage: selectedblogPage,
        offsetblog: offsetblog,
      },
      () => {
        this.viewAllData();
      }
    );
  };

  myBlogsData = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/blogs/author/ ${localStorage.getItem(
        'studentId'
      )}`,
      {
        headers: {
          'x-access-token': localStorage.getItem('jwtToken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const quoteList = data.blogs;
        const slice = quoteList.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        const postData = slice.map((data, index) => this.getCard(data, index));
        this.setState({
          pageCount: Math.ceil(quoteList.length / this.state.perPage),
          postData,
        });
      });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.myBlogsData();
      }
    );
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.searchBlogs(e.target.search.value);
  };

  searchBlogs = (term, isTagFilter) => {
    isTagFilter = isTagFilter ? isTagFilter : '';
    this.setState({ myblog: 'search' });
    fetch(
      `${process.env.REACT_APP_API_URL}/search/blogs?searchParam=${term}&isTagFilter=${isTagFilter}`,
      {
        headers: {
          'x-access-token': localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const searchList = data.blogs ? data.blogs : [];
        const search = searchList.slice(
          this.state.searchoffset,
          this.state.searchoffset + this.state.perPage
        );
        const searchData = search.map((data, index) => (
          <React.Fragment>
            <div className="col-md-4">
              <div
                className={
                  'mx-auto single-blog-card card border shadow-sm mb-5'
                }
                style={{ width: '300px' }}
              >
                <div className="blogs-tag">
                  {data.tags !== null
                    ? data.tags.map((tags, key) => (
                        <span
                          key={key}
                          className="mar-r-8 badge badge-pill badge-primary"
                        >
                          {tags.value}
                        </span>
                      ))
                    : null}
                </div>
                <img
                  src={data.cover}
                  className="card-img-top position-relative"
                  alt="blog"
                  style={{ height: '160px' }}
                  onClick={() =>
                    this.props.history.push(`singleblog/${data.id}`)
                  }
                />

                <div
                  className="card-body"
                  style={{
                    maxHeight: '150px',
                    color: '#435370',
                    padding: '14px',
                  }}
                >
                  <h3
                    style={{
                      cursor: 'pointer',
                      minHeight: 'unset',
                      fontSize: '18px',
                      fontWeight: '800',
                    }}
                    className="h5 card-title"
                    onClick={() =>
                      this.props.history.push(`singleblog/${data.id}`)
                    }
                  >
                    {data.title.length > 80
                      ? data.title.substring(0, 80) + '...'
                      : data.title}
                  </h3>
                  <p
                    className="card-text"
                    style={{ lineHeight: '1.35', color: 'rgb(60, 76, 107)' }}
                  >
                    {data.excerpt.length > 120
                      ? data.excerpt.substring(0, 120) + '...'
                      : data.excerpt}
                  </p>
                </div>

                {data.student_data.map((student_data, index) => (
                  <div className="d-flex" style={{ width: '100%' }}>
                    <div
                      style={{ fontSize: '12px', width: '100%' }}
                      className="d-flex p-2"
                    >
                      <img
                        className="img blog-profile-pic mx-2"
                        src={student_data.profile_pic}
                        alt="User-Icon"
                      />

                      <span>{student_data.first_name}</span>
                    </div>
                    <div
                      className="d-flex justify-content-end"
                      style={{ width: '100%' }}
                    >
                      <a
                        href={`/singleblog/${data.id}`}
                        className="btn btn-primary enroll-button py-2 text-white"
                      >
                        Read full blog
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        ));
        this.setState({
          searchArgs: { term, isTagFilter },
          searchpageCount: Math.ceil(searchList.length / this.state.perPage),
          searchData,
        });
      });
  };

  handleSearchPageClick = (e) => {
    const selectedsearchPage = e.selected;
    const searchoffset = selectedsearchPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedsearchPage,
        searchoffset: searchoffset,
      },
      () => {
        this.searchBlogs(
          this.state.searchArgs.term,
          this.state.searchArgs.isTagFilter
        );
      }
    );
  };

  switchRender(type) {
    switch (type) {
      case 'my':
        return (
          <div className="row">
            {this.state.postData}
            {this.state.postData.length > 0 ? (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                forceSelected={this.state.currentPage}
                pageRangeDisplayed={4}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            ) : (
              <div>
                <p>
                  Oops! You haven't written any blogs yet. Please click on "Add
                  Blog" to write your first blog. Happy blogging!
                </p>
              </div>
            )}
          </div>
        );
      case 'all':
        return (
          <div className="row">
            {this.state.blogData}
            {this.state.blogData.length > 0 ? (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.blogpageCount}
                forceSelected={this.state.currentblogPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handleBlogPageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            ) : (
              <div>
                <p>
                  Oops! You haven't written any blogs yet. Please click on "Add
                  Blog" to write your first blog. Happy blogging!
                </p>
              </div>
            )}
          </div>
        );
      case 'search':
        return (
          <div className="row">
            {this.state.searchData}
            {this.state.searchData.length > 0 ? (
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.searchpageCount}
                forceSelected={this.state.currentblogPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={this.handleSearchPageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            ) : (
              <div>
                <p>
                  Oops! We were not able to find any blogs related to your
                  search. Please look for other search parameters or write one
                  yourself by clicking on "Add Blog".
                </p>
              </div>
            )}
          </div>
        );
    }
  }

  handleTagFilter = (selectedTag) => {
    this.setState({ selectedTag });
    this.searchBlogs(selectedTag.label, true);
  };

  render() {
    return (
      <React.Fragment>
        <section className="our-blog-section gray-light-bg single-blog-container">
          {this.state.first_time_blog && (
            <div className="blog-container container mx-auto shadow rounded ">
              <div className="row mx-0">
                <div className="col-12 px-0">
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <p className="mb-0">
                      This is where all the Blogs reside, written by Teachers,
                      Experts and also your Peers!
                    </p>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="blog-container container mx-auto shadow rounded">
            <div className="d-flex justify-content-between">
              <h2>Blogs</h2>
              <a href="/add-newblog" className="btn-blue my-2 pl-3 pr-3 ">
                <AiOutlinePlus className="mr-1" />
                Add New Blog
              </a>
            </div>
            <p className="sub-heading">
              Go through insightful blogs and share your knowledge and
              experiences through this Blog section!
            </p>
            <div className="row">
              <div className="col-md-7 d-flex flex-column flex-md-row mb-3 mb-md-0">
                <button
                  className={
                    this.state.myblog === 'all'
                      ? 'btn-blue mar-b-20'
                      : 'btn-blue-border mar-b-20'
                  }
                  style={{
                    backgroundColor: this.state.color,
                    color: this.state.color,
                  }}
                  onClick={() =>
                    this.setState({ myblog: 'all', selectedTag: null })
                  }
                >
                  View All
                </button>

                <button
                  className={
                    this.state.myblog === 'my'
                      ? 'btn-blue mar-b-20'
                      : 'btn-blue-border mar-b-20'
                  }
                  onClick={() =>
                    this.setState({
                      myblog: 'my',
                      currentPage: 1,
                      selectedTag: null,
                    })
                  }
                >
                  My Blogs
                </button>

                <div className="w-100 w-md-30" style={{ zIndex: 999 }}>
                  <Select
                    placeholder="Filter through tags"
                    className="form-select"
                    value={this.state.selectedTag}
                    onChange={this.handleTagFilter}
                    options={this.state.tags}
                  />
                </div>
              </div>
              <div className="col-md-5 mb-3 mb-md-0">
                <form className="elem-float-right" onSubmit={this.onSubmit}>
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Blog"
                    className="search-input"
                  />
                  <input
                    type="submit"
                    value="Search"
                    className="btn-blue mr-0"
                  />
                </form>
              </div>
            </div>
            {this.switchRender(this.state.myblog)}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(Blog);
