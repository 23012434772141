import React, { Component } from 'react';

import Header from '../components/Header/header';
import SecondHeader from '../components/Header/secondheader';
import FooterSection from '../components/Footer';

class Disclaimer extends Component {
  render() {
    return (
      <React.Fragment>
        {localStorage.getItem('jwtToken') == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}

        <div className="pt-265p pt-md-200p">
          <div className="blog-container shadow mt-0">
            <main className="container page-participation-guidelines pb-0">
              <h1 className="mar-b-30 text-center">Disclaimer</h1>
              <p>
                The information provided by (“we,” “us” or “our”) on{' '}
                <a
                  href="https://aifacilitator.community"
                  style={{ color: '#0056b3' }}
                >
                  https://aifacilitator.community
                </a>{' '}
                (the “Site”) and our web portal are for general informational
                purposes only. All information on the Site and our web portal is
                provided in good faith, however, we make no representation or
                warranty of any kind, express or implied, regarding the
                accuracy, adequacy, validity, reliability, availability, or
                completeness of any information on the Site or our web portal
                UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY
                LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
                THE SITE OR OUR WEB PORTAL OR RELIANCE ON ANY INFORMATION
                PROVIDED ON THE SITE AND OUR WEB PORTAL. YOUR USE OF THE SITE
                AND OUR WEB PORTAL AND YOUR RELIANCE ON ANY INFORMATION ON THE
                SITE AND OUR WEB PORTAL IS SOLELY AT YOUR OWN RISK.
              </p>

              <h2 className="left-blue-border mar-b-30 text-left mt-4">
                External Links Disclaimer
              </h2>
              <p>
                The Site and our web portal may contain (or you may be sent
                through the Site or our web portal) links to other websites or
                content belonging to or originating from third parties or links
                to websites and features in banners or other advertising. Such
                external links are not investigated, monitored, or checked for
                accuracy, adequacy, validity, reliability, availability, or
                completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR
                ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY
                INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE
                SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER
                ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
                RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
                THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
              </p>

              <h2 className="left-blue-border mar-b-30 text-left mt-4">
                For Learning Resources
              </h2>
              <p>
                CBSE reserves the right to modify the Learning Resources
                provided under various sections on the site.
                <br />
                <br />
                Learning Resources includes the videos, documents, blogs, and
                external resources on an "as is" and "as available" basis. You,
                therefore, use these at your own risk. CBSE expressly disclaims
                any and all warranties of any kind, whether express or implied,
                including, but not limited to the implied warranties of
                merchantability, fitness for a particular purpose,
                non-infringement, and any other warranty that might arise under
                any law. Without limiting the foregoing, CBSE makes no
                representations or warranties:
                <ul className="section-list">
                  <li>
                    That the Learning Resources will be permitted in your
                    jurisdiction;
                  </li>
                  <li>
                    That the Learning Resources will be uninterrupted or
                    error-free;
                  </li>
                  <li>
                    That the Learning Resources will meet your professional or
                    educational needs;
                  </li>
                  <li>
                    The Learning Resources including but not limited to any
                    third-party video(s), blogs, reference, links have been
                    provided on an "as is" basis without warranty of any kind,
                    whether express or implied.
                  </li>
                </ul>
              </p>

              <h2 className="left-blue-border mar-b-30 text-left mt-4">
                For Forum and Blogs
              </h2>
              <p>
                Discussion content on forums and blogs posted on the site
                reflects the views of individuals only. CBSE bears no
                responsibility for the accuracy of a member’s comments on forums
                and their blogs and will bear no responsibility or legal
                liability for the same.
              </p>
            </main>
          </div>
        </div>
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default Disclaimer;
