/* eslint-disable no-unused-vars */
import React from 'react';
import Header from '../Header/header';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import axios from 'axios';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import PropTypes from 'prop-types';
import { registerUser } from '../../actions/authAction';
import stateName from '../DataJson/IndianStates.json';
import Footer from '../Footer';
import { countries } from '../../constants/countries';

const options = [
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
  { value: 3, label: 'Other' },
];

class signUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateName: stateName,
      statename: '',
      isAgreed: false,
      disableBtn: false,
      selectedGender: 1,
      selectedSchoolType: 'gov',
      selectedCBSE: 'true',
      btnText: 'Sign up',
      teacherSignUp: 'Teacher',
      country: 'IN',
    };
  }
  createNotification = (type) => {
    // return () => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'info':
        NotificationManager.info('Info message');
        break;
      case 'success':
        NotificationManager.success('Successfully update', 'Update');
        break;
      case 'warning':
        NotificationManager.warning(
          'Warning message',
          'Close after 3000ms',
          3000
        );
        break;
      case 'error':
        NotificationManager.error(
          'Warning message',
          'Account Already exists with this email address',
          7000,
          () => {}
        );
        break;
    }
  };

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  componentDidMount() {
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleSchoolType = this.handleSchoolType.bind(this);
    this.handleSelectedCBSE = this.handleSelectedCBSE.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleGenderChange = (event) => {
    this.setState({ selectedGender: event.target.value });
  };

  handleSchoolType = (event) => {
    this.setState({ selectedSchoolType: event.target.value });
  };

  handleSelectedCBSE = (event) => {
    this.setState({ selectedCBSE: event.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const newUser = {
      first_name: e.target.first_name.value,
      middle_name: e.target.middle_name.value,
      last_name: e.target.last_name.value,
      email_id: e.target.email.value,
      password: e.target.password.value,
      date_of_birth: this.state.date_of_birth,
      school_type: this.state.selectedSchoolType,
      gender: this.state.selectedGender,
      cbse_ai_thon: this.state.selectedCBSE,
      school: e.target.school.value,
      city: e.target.city.value,
      country: this.state.country,
      state: this.state.country === 'IN' ? this.state.statename : '',
      designation: e.target.designation.value,
      role: 1,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/register/student`, newUser)
      .then((res) => {
        this.props.history.push('/unverification');
      })
      .catch((err) => {
        this.createNotification('error');
        this.props.registerUser(err);
      });
  };

  render() {
    const form = (
      <div className="card login-signup-card shadow-lg mb-0">
        <div className="card-body px-md-5 py-5">
          <form
            className="login-signup-form"
            method="post"
            onSubmit={this.onSubmit}
          >
            <div
              className="row"
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="pb-1">First Name*</label>
                  <div className="input-group input-group-merge">
                    <input
                      value={this.state.first_name}
                      onChange={this.onChange}
                      type="text"
                      name="first_name"
                      className="form-control"
                      placeholder="First Name"
                      pattern="^[a-zA-z\s\.]+$"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="pb-1">Middle Name</label>
                  <div className="input-group input-group-merge">
                    <input
                      value={this.state.middle_name}
                      onChange={this.onChange}
                      type="text"
                      name="middle_name"
                      className="form-control"
                      placeholder="Middle Name"
                      pattern="^[a-zA-z\s\.]+$"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group col-sm-4">
                <label className="pb-1">Last Name*</label>
                <div className="input-group input-group-merge">
                  <input
                    value={this.state.last_name}
                    onChange={this.onChange}
                    type="text"
                    name="last_name"
                    className="form-control"
                    placeholder="Last name"
                    pattern="^[a-zA-z\s\.]+$"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-6">
                <label className="pb-1">Email Address*</label>
                <div className="input-group-signup input-group-merge">
                  <input
                    onChange={this.onChange}
                    value={this.state.email}
                    id="email"
                    name="email"
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    className="form-control"
                    placeholder="name@yourdomain.com"
                    title="Please enter a valid email address"
                    required
                  />
                </div>
                <small>
                  <span>Don’t have an e-mail account?&nbsp;</span>
                  <a
                    href="https://www.youtube.com/watch?v=koJlF6YDqqA"
                    className="text-carnation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here
                  </a>
                  <span>&nbsp;to know how to create one!</span>
                </small>
              </div>
              <div className="form-group col-sm-6">
                <label className="pb-1">Password*</label>
                <div className="input-group-signup input-group-merge">
                  <input
                    value={this.state.password}
                    onChange={this.onChange}
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Enter a new password for AIFC"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className={
                  this.state.country === 'IN'
                    ? 'form-group col-sm-4'
                    : 'form-group col-sm-6'
                }
              >
                <label className="pb-1">Country*</label>
                <div className="input-group-signup input-group-merge">
                  <select
                    className="form-control ml-0 my-0"
                    value={this.state.country}
                    onChange={(event) =>
                      this.setState({ country: event.target.value })
                    }
                    required
                  >
                    <option value="">Select Country</option>
                    {countries.map((country, index) => (
                      <option
                        value={country.key}
                        key={index}
                        dangerouslySetInnerHTML={{ __html: country.value }}
                      />
                    ))}
                  </select>
                </div>
              </div>
              {this.state.country === 'IN' && (
                <div className="form-group col-sm-4">
                  <label className="pb-1">State/UT*</label>
                  <div className="input-group-signup input-group-merge">
                    <select
                      className="form-control ml-0 my-0"
                      value={this.state.statename}
                      onChange={(event) =>
                        this.setState({ statename: event.target.value })
                      }
                      required
                    >
                      <option value="">Select State/UN</option>
                      {this.state.stateName.state.map((state, index) => (
                        <option value={state.key} key={index}>
                          {state.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div
                className={
                  this.state.country === 'IN'
                    ? 'form-group col-sm-4'
                    : 'form-group col-sm-6'
                }
              >
                <label className="pb-1">City*</label>
                <div className="input-group-signup input-group-merge">
                  <input
                    value={this.state.city}
                    onChange={this.onChange}
                    type="text"
                    name="city"
                    className="form-control"
                    placeholder="City"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <>
                <div className="form-group col-sm-6">
                  <label className="pb-1">Gender*</label>
                  <div className="input-group-signup input-group-merge">
                    <select
                      defaultValue={this.state.selectedGender}
                      className="form-control my-0 ml-0"
                      onChange={this.handleGenderChange}
                    >
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      <option value="3">Other</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-sm-6">
                  <label className="pb-1">Designation*</label>
                  <input
                    value={this.state.designation}
                    onChange={this.onChange}
                    type="text"
                    name="designation"
                    className="form-control"
                    placeholder="Designation"
                    required
                  />
                </div>
              </>

              <div className="form-group col-sm-6">
                <label className="pb-1">Name of your Institution*</label>
                <div className="input-group input-group-merge">
                  <input
                    value={this.state.school}
                    onChange={this.onChange}
                    type="text"
                    name="school"
                    className="form-control"
                    placeholder="Name of your Institution"
                    required
                  />
                </div>
              </div>
              <div className="form-group col-sm-6 ">
                <label className="pb-1">Institution Type*</label>
                <div className="input-group-signup input-group-merge">
                  <select
                    className="form-control ml-0 my-0"
                    defaultValue={this.state.selectedSchoolType}
                    onChange={this.handleSchoolType}
                  >
                    <option value="gov">Government</option>
                    <option value="private">Private</option>
                    <option value="govtaided">Govt. aided</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row mx-0 mt-5">
              <div className="col-12 text-center">
                <button
                  className="btn-theme-primary mx-auto text-center px-5 signup-btn"
                  id="signIn"
                  disabled={this.state.disableBtn}
                  onClick={() => {
                    this.changeBtnText('Signing Up...');
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="card-footer px-md-5 py-2 bg-transparent border-top">
          <small>Already have an account? </small>
          <a href="/login" className="small text-carnation">
            Sign in
          </a>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <Header />
        <NotificationContainer />
        <div className="pt-265p pt-md-200p">
          <section className="page-signup pt-30 pb-50">
            <div className="container">
              <div className="col-md-12 col-lg-12 ">
                <div>
                  <h3 className="page-title">Sign up</h3>
                  <p style={{ textAlign: 'center' }}>
                    Complete the form below to sign up for the community.
                  </p>
                </div>
                {form}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

signUp.propTypes = {
  registerUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    error: state.authFail.error,
  };
};

export default connect(mapStateToProps, { registerUser })(withRouter(signUp));
