import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { listAllCategories } from '../../actions/forumindex';

class CategoriesDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  handleInputChange(field) {
    let href = this.props.isLink;
    if (href) {
      window.location.href = '/' + field;
    }
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/forums/categories`, {
      headers: {
        'x-access-token': localStorage.getItem('jwtToken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) =>
        this.setState({
          data: data.categories,
        })
      );
  }

  render() {
    return (
      <div className="dropdown-categs">
        <select
          name="category_id"
          className="form-control"
          onChange={(field) => this.handleInputChange(field.target.value)}
          required
        >
          {this.state.data.map((categories) => (
            <option value={categories.id} key={categories.path}>
              {categories.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

CategoriesDropdown.propTypes = {
  isLink: PropTypes.bool.isRequired,
  firstValue: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    listCategories: state.postCategories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  listAllCategories: () => dispatch(listAllCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesDropdown);
