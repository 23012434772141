import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
// import {errorHandle} from '../components/Auth/signup'

import { SET_CURRENT_USER, USER_LOADING, SET_AUTH_FAIL } from './type';

// Register User

export const registerUser = (err, history) => (dispatch) => {
  dispatch(authFail(err));
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/login/student`, userData)
    .then((res) => {
      // Save to localStorage

      // Set token to localStorage
      const { token, id } = res.data;
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('studentId', id);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .then((res) => {
      if (localStorage.getItem('jwtToken') !== null) {
        if (userData.previousUrl) {
          window.location.href = `.${userData.previousUrl}`;
        } else {
          window.location.href = './dashboard';
        }
      }
    })
    .catch((err) => {
      dispatch(authFail(err));
    });
};

export const ssoLoginUser = (userData) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/sso/discourse`, userData)
    .then((res) => {
      // Save to localStorage

      // Set token to localStorage
      const { token, id } = res.data;
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('studentId', id);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));

      if (res.data.ssoLoginString) {
        localStorage.setItem('ssoLoginString', res.data.ssoLoginString);
        window.location =
          process.env.REACT_APP_FORUM_REDIRECT_LOGIN_URL +
          '?' +
          res.data.ssoLoginString;
      }
    })
    .catch((err) => {
      dispatch(authFail(err));
    });
};

export const ssoLoginUserViaId = (userData) => (dispatch) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/sso/discourseViaStudentId`,
      userData
    )
    .then((res) => {
      localStorage.setItem('ssoLoginString', res.data.ssoLoginString);
      window.location =
        process.env.REACT_APP_FORUM_REDIRECT_LOGIN_URL +
        '?' +
        res.data.ssoLoginString;
    })
    .catch((err) => {
      dispatch(authFail(err));
    });
};

// auth fail
export const authFail = (error) => {
  return {
    type: SET_AUTH_FAIL,
    payload: error,
  };
};
// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // logout from forum
  // call aifc-core to log out user from discourse
  let options = {
    headers: {
      'x-access-token': localStorage.getItem('jwtToken'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let data = {
    studentId: localStorage.getItem('studentId'),
  };
  axios
    .post(`${process.env.REACT_APP_API_URL}/sso/logout`, data, options)
    .then((res) => {
      console.log(res);
    });

  // Remove token from local storage
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('token');
  localStorage.removeItem('studentId');
  localStorage.removeItem('count');
  localStorage.clear();
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
